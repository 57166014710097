<template>
  <section class="latest-news-section px-6 lg:px-20 anim" id="latest-news-section">
    <h2><b>Latest News</b></h2>
    <div v-if="events.length" class="news-container pb-6">
      <div
        v-for="event in events"
        :key="event.id"
        class="event-card min-w-80 pb-10"
        @click="navigateTo(event.link)"
      >
        <img :src="event.image" :alt="event.title" class="event-image" />
        <p class="event-date">{{ event.date }}</p>
        <h3>{{ event.title }}</h3>
        <p class="news-description line-clamp-6">{{ event.description }}</p>
        <a :href="event.link" target="_blank" class="read-more" @click.stop>Read More</a>
      </div>
    </div>
    <p v-else>Sorry but there isn't any latest news.</p>
  </section>
</template>

<script>
export default {
  name: 'latest-news-section',
  data() {
    return {
      events: [
        {
          id: 7,
          title: '23-year-old Azerbaijani youth building a mobile app in London',
          date: '18-01-2025',
          description: 'Mirkazim Seyidzadeh recounts first coming to London as a student and facing many problems, which inspired him to create a mobile application to support other students.',
          link: 'https://azertag.az/xeber/londonda_mobil_tetbiq_quran_23_yasli_azerbaycanli_genc_hedefimiz_britaniyanin_en_yaxsisi_olmaqdir__musahibe-3378259',
          // eslint-disable-next-line global-require
          image: require('@/assets/NewsSection/azertag.png'),
        },
        {
          id: 6,
          title: 'TALAB: Your Complete Solution for Stress-Free Student Life in the UK',
          date: '20-12-2024',
          description: 'TALAB, launched in June 2024 by Mirkazim Seyidzade and Sahib Zulfigar, is the first student concierge app. With 4,500+ monthly users, it simplifies UK student life by offering housing, visa help, job opportunities, and discounts.',
          link: 'https://techbullion.com/talab-the-ultimate-all-in-one-platform-for-simplifying-student-life-in-the-uk/',
          // eslint-disable-next-line global-require
          image: require('@/assets/NewsSection/TechBullion-colored-logo.png'),
        },
        {
          id: 5,
          title: 'TALAB: Redefining Convenience for Students in the UK',
          date: '20-12-2024',
          description: 'TALAB streamlines student life in the UK with services like accommodation and academic resources. Founded by international students, it aims to empower users by solving everyday challenges and fostering community growth.',
          link: 'https://www.benzinga.com/pressreleases/24/12/42632329/talab-the-ultimate-all-in-one-platform-for-simplifying-student-life-in-the-uk',
          //   image: '/frontend/src/assets/NewsSection/Benzinga.png',
          // eslint-disable-next-line global-require
          image: require('@/assets/NewsSection/Benzinga.png'),
        },
        {
          id: 4,
          title: 'TALAB: The Ultimate All-in-One Platform for Simplifying Student Life in the UK',
          date: '20-12-2024',
          description: 'Mirkazim Seyidzade and Sahib Zulfigar are redefining student life in the UK with TALAB, the world’s first student concierge app.',
          link: 'https://zexprwire.com/talab-the-ultimate-all-in-one-platform-for-simplifying-student-life-in-the-uk/',
          // eslint-disable-next-line global-require
          image: require('@/assets/NewsSection/ZEXPRWIRE.png'),
        },
        {
          id: 3,
          title: 'TALAB: The One-Stop Hub for Enhancing UK Student Life',
          date: '19-12-2024',
          description: 'TALAB, a concierge app for students, offers services like visa support, airport pickups, and event access. With thousands of users, it reduces stress and builds connections for students in the UK.',
          link: 'https://www.digitaljournal.com/pr/news/binary-news-network/talab-ultimate-all-in-one-platform-simplifying-1973776088.html',
          //   image: '/frontend/src/assets/NewsSection/DigitalJournal.png',
          // eslint-disable-next-line global-require
          image: require('@/assets/NewsSection/DigitalJournal.png'),
        },
        {
          id: 2,
          title: 'Presentation of mobile application created by Azerbaijani youth held at the State Committee',
          date: '28-06-2024',
          description: 'The article highlights "Talab," a mobile app created by Azerbaijani students Mirkazım Seyidzadeh and Sahib Zulfiqar to assist students in London. Presented at the State Committee for Work with the Diaspora, Seyidzadeh shared how the app addresses challenges like housing and adapting to a new environment. Designed in nine months, "Talab" helps users connect with peers, find rentals, arrange airport pick-ups, and access other services. The app has quickly gained interest among students.',
          link: 'https://diaspor.gov.az/index.php/az/news-detail/dovlet-komitesinde-azerbaycanli-genclerin-yaratdigi-mobil-tetbiqin-teqdimati-kecirilib-4902',
          // eslint-disable-next-line global-require
          image: require('@/assets/NewsSection/SCOWWDOTROA.jpeg'),
        },
        {
          id: 1,
          title: 'Azerbaijani Youth Create "Talab" Mobile App to Support Students in London',
          date: '08-06-2024',
          description: 'Talab, a mobile app created by Azerbaijani youth to help foreign students in London. Co-founders Mirkazım Seyidzadeh and Sahib Zulfiqar designed the app to address challenges like housing and adapting to a new environment. Features include connecting with peers, finding rentals, airport pick-ups, and visa assistance. Already downloaded by over 500 users, the app aims to simplify student life in the UK.',
          link: 'https://az.trend.az/azerbaijan/society/3909806.html',
          // eslint-disable-next-line global-require
          image: require('@/assets/NewsSection/TrendNewsAgency.jpeg'),
        },
      ],
    };
  },
  methods: {
    navigateTo(link) {
      window.open(link, '_blank');
    },
  },
};
</script>

<style>
.latest-news-section {
  display: grid;
  margin: 0 auto;
  margin-bottom: 0px;
  background-color: rgba(255, 255, 255, 0.829);
  /* background-color: rgb(0, 0, 105); */
  color: black;
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.latest-news-section h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 35px;
}

.news-container {
  display: flex;
  /* align-items: row; */
  /* grid-template-columns: repeat(3, 1fr); */
  overflow-x: auto;
  gap: 20px;
}

.event-card {
  background: white;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 15px ;
  /* min-width: 500px; */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  /* margin-bottom: 15px; */
}

.event-card:hover{
  background-color: whitesmoke;
}

.event-image {
  height: 30px;
  object-fit: cover;
}

.event-card h3 {
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.event-date {
  color: #888;
  font-size: 0.9rem;
  margin: 5px 0;
}

.news-description {
  margin: 10px 0;
  font-size: 1rem;
}

.read-more {
  color: #6200ee;
  text-decoration: none;
  font-weight: bold;
}

.read-more:hover {
  text-decoration: underline;
}

/* Mobile view */
@media (max-width: 900px){
  .news-container{
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
</style>
